.review {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -30px;

    &__top {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        padding-right: 55px;
        margin-bottom: 14px;
    }

    &__name {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #4B4B4B;
    }

    &__body {
        p {
            font-size: 14px;
            line-height: 160%;
            margin: 0 0 10px 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 14px;
        background-position: center;
        background-size: cover;
    }

    &__date {
        position: absolute;
        right: 0;
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.5);
    }

    &__post {
        font-size: 12px;
        line-height: 15px;
        color: #4B4B4B;
        margin-top: 5px;
    }

    &__item {
        width: calc(~"50% - 30px");
        padding: 20px 25px;
        margin: 0 15px 30px;
        background-color: #FFFFFF;
        border-radius: 1px;
    }
}

@media(max-width: 960px) { 
    .review {
        flex-direction: column;
        align-items: center;
        margin: 0 0 -30px;

        &__item {
            width: 488px;
            margin: 0 0 30px;
        }
    }
}

@media(max-width: 640px) { 
    .review {
        &__item {
            width: 100%;
        }
    }
}
.section {
    padding: 61px 0;

    &--big {
        padding: 101px 0;
    }

    &--grey {
        background-color: #F1F1F1;

        h2 {
            color: #4B4B4B;
        }
    }

    h2 {
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        margin: 0 0 32px 0;
    }

    &__title {
        display: flex;
        align-items: flex-end;

        h2 {
            margin: 0;
            margin-right: 16px;
        }

        a {
            display: inline-block;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: rgba(0, 0, 0, 0.5);
            text-transform: lowercase;
            margin-bottom: 3px;
        }

        margin: 0 0 32px 0;
    }
}

@media(max-width: 1210px) {
    .section {
        &--big {
            padding: 70px 0;
        }
    }
}

@media(max-width: 640px) {
    .section {
        padding: 50px 0;

        h2 {
            font-size: 22px;
            line-height: 27px;
        }
    }
}

body {
    padding: 0;
    margin: 0;
    outline: 0;
}

.site-content {
    overflow-x: hidden;
}

.conj-col__full--index {
    display: block!important;
    padding: 0 30px;
}

.conj-col__full--flex {
    display: flex !important;
    flex-wrap: wrap;
}

@media(max-width: 640px) { 
    .conj-col__full--index {
        padding: 0 26px;
    }
}
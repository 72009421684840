.offers {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -30px;

    &__img {
        height: 200px;
        background-position: center;
        background-size: cover;
        position: relative;
        margin-bottom: 12px;
    }

    &__date {
        font-size: 13px;
        line-height: 128.91%;
        color: #fff;
        background: #16821a;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 4px 11px;
    }

    &__more {
        .svg-icon {
            display: block;

            use {
                fill: rgba(0, 0, 0, 0.5);
                transition: fill 0.3s ease;
            }
        }
    }

    &__text {
        margin-bottom: 32px;
        color: #4B4B4B;
    }

    &__item {
        display: flex;
        flex-direction: column;
        width: calc(~"25% - 30px");
        margin: 0 15px 30px;
        font-size: 14px;
        line-height: 142.97%;
    }
}

@media(max-width: 1199px) {
    .offers {
        &__item {
            width: calc(~"33.3% - 30px");

            &:nth-child(4) {
                display: none;
            }
        }
    }
}

@media(max-width: 960px) {
    .offers {
        &__item {
            width: calc(~"50% - 30px");

            &:nth-child(3) {
                display: none;
            }
        }
    }
}

@media(max-width: 480px) {
    .offers {
        &__item {
            width: calc(~"100% - 30px");

            &:nth-child(2) {
                display: none;
            }
        }
    }
}
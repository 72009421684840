.check {
    position: relative;
    display: inline-flex;
    margin-right: 5px;
    flex: none;

    input {
        cursor: pointer;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &__control {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        background-color: #FFFFFF;
        border-radius: 1px;
    }
}
.level {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 67px auto -72px;
    width: 80%;

    &__item {
        width: calc(~"50% - 74px");
        margin: 0 37px;
        position: relative;
        margin-bottom: 72px;
    }

    &__text {
        font-size: 12px;
        line-height: 150%;
        color: #4b4b4b;
        letter-spacing: -.01em;
        padding-left: 7px;

        ul {
            padding: 0;
            margin: 0;

            li {
                padding-left: 8px;
                position: relative;
                list-style: none;
                margin-bottom: 12px;

                &::before {
                    content: "";
                    position: absolute;
                    width: 2px;
                    height: 2px;
                    left: -2px;
                    top: 10px;
                    border-radius: 50%;    
                    background-color: #EE370F;  
                }
            }
        }
    }

    &__number {
        position: absolute;
        left: -42px;
        top: -56px;
        font-weight: 800;
        font-size: 90px;
        line-height: 110px;
        color: #4B4B4B;
        letter-spacing: -0.01em;
        color: rgba(75, 75, 75, 0.2);
        text-transform: uppercase;
    }

    &__name {
        font-weight: 600;
        font-size: 15px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #EE370F;
        margin-bottom: 16px;
    }
}

@media(max-width: 1210px) { 
    .level {
        width: 75%;
        margin-bottom: -48px;

        &__item {
            margin-bottom: 48px;
        }
    }
}

@media(max-width: 960px) { 
    .level {
        width: 480px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -69px;
        margin-top: 64px;

        &__item {
            width: 100%;
            padding: 0;
            margin-bottom: 69px;
        }
    }
}

@media(max-width: 640px) {
    .level {
        margin-top: -15px;
        margin-bottom: -13px;

        &__name {
            margin-bottom: 28px;
        }

        &__number {
            position: relative;
            left: initial;
            top: initial;
            text-align: center;
            margin-bottom: -54px;
        }

        &__item {
            width: 358px;
            margin-bottom: 13px;
        }
    }
}

@media(max-width: 480px) { 
    .level {
        &__item {
            width: 100%;
        }
    }
}
.form {
    color: #4B4B4B;

    &__title {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        line-height: 140%;
        margin-bottom: 8px;

        svg {
            margin-right: 12px;
        }
    }

    &__btn {
        margin-top: 11px;

        .button {
            width: 100%;
            font-weight: bold !important;
            min-height: 36px !important;
            padding: 9px 27px !important;
        }
    }

    &__field {
        height: 36px;
        margin-bottom: 12px;
    }

    &__input {
        font-size: 14px;
        line-height: 26px;
        letter-spacing: -.01em;
        padding: 0 !important; 
        text-align: center;
        height: 100%;
        width: 100%;
        color: #4B4B4B !important; 
        background-color: #fff !important; 
        min-height: initial !important;
        border-radius: 1px !important; 
        box-shadow: none !important;
    }

    &__text {
        font-size: 12px;
        line-height: 150%;
        margin-bottom: 13px;
        color: #4B4B4B;

        a {
            color: #4B4B4B;
            font-weight: bold;
            text-decoration: none;
        }
    }
}

@media(max-width: 1200px) {
    .form {
        &__body {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
        }

        &__btn {
            order: 1;
            width: calc(~"33.3% - 20px");
            margin: 0 10px;
        }

        &__field {
            order: 1;
            width: calc(~"33.3% - 20px");
            margin: 0 10px;
        }

        .agree {
            order: 2;
            margin: 10px 10px 0;
        }
    }
}

@media(max-width: 800px) {
    .form {

        &__body {
            display: block;
        } 

        &__field {
            width: 100%;
            margin: 0 0 14px 0;
        }

        &__btn {
            width: 100%;
            margin: 0 0 14px 0;
        }

        .agree {
            width: 100%;
            margin: 0 0 14px 0;
        }
    }
}

.agree {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    line-height: 150%;
    color: rgba(0,0,0,.5);

    a {
        color: rgba(0,0,0,.5);
    }

    .check {
        margin-top: 2px;
    }
}

.aside-form {
    width: 300px;
    padding: 22px 20px;
    background-color: #F1F1F1;
}

@media(max-width: 1200px) {
    .aside-form {
        width: 100%;
        padding: 22px 25px;
    }
}

.main-block {
    padding: 50px 0;
}
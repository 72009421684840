.advantages {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -45px;

    &__item {
        width: 25%;
        padding: 0 15px;
        margin-bottom: 45px;
    }

    &__text {
        line-height: 1.6;
    }

    &__icon {
        margin-bottom: 18px;
    }

    &__name {
        font-weight: 700;
        font-size: 18px;
        line-height: 136%;
        margin-bottom: 24px;
    }
}

@media(max-width: 1210px) {
    .advantages {
        width: auto;
        margin-left: auto;
        margin-right: auto;

        &__item {
            width: 50%;
            padding: 0 47px;
        }
    }
}

@media(max-width: 960px) { 
    .advantages {
        justify-content: center; 
        
        &__icon {
            text-align: center;
        }

        &__name {
            text-align: center;
        }

        &__item {
            width: 490px;
            padding: 0;
        }
    }
}

@media(max-width: 640px) {
    .advantages {
        &__item {
            width: 370px;
        }
    }
}

@media(max-width: 480px) { 
    .advantages {
        &__item {
            width: 100%;
        }
    }
}
.section {
  padding: 61px 0;
}
.section--big {
  padding: 101px 0;
}
.section--grey {
  background-color: #F1F1F1;
}
.section--grey h2 {
  color: #4B4B4B;
}
.section h2 {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin: 0 0 32px 0;
}
.section__title {
  display: flex;
  align-items: flex-end;
  margin: 0 0 32px 0;
}
.section__title h2 {
  margin: 0;
  margin-right: 16px;
}
.section__title a {
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);
  text-transform: lowercase;
  margin-bottom: 3px;
}
@media (max-width: 1210px) {
  .section--big {
    padding: 70px 0;
  }
}
@media (max-width: 640px) {
  .section {
    padding: 50px 0;
  }
  .section h2 {
    font-size: 22px;
    line-height: 27px;
  }
}
body {
  padding: 0;
  margin: 0;
  outline: 0;
}
.site-content {
  overflow-x: hidden;
}
.conj-col__full--index {
  display: block!important;
  padding: 0 30px;
}
.conj-col__full--flex {
  display: flex !important;
  flex-wrap: wrap;
}
@media (max-width: 640px) {
  .conj-col__full--index {
    padding: 0 26px;
  }
}
.level {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 67px auto -72px;
  width: 80%;
}
.level__item {
  width: calc(50% - 74px);
  margin: 0 37px;
  position: relative;
  margin-bottom: 72px;
}
.level__text {
  font-size: 12px;
  line-height: 150%;
  color: #4b4b4b;
  letter-spacing: -0.01em;
  padding-left: 7px;
}
.level__text ul {
  padding: 0;
  margin: 0;
}
.level__text ul li {
  padding-left: 8px;
  position: relative;
  list-style: none;
  margin-bottom: 12px;
}
.level__text ul li::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  left: -2px;
  top: 10px;
  border-radius: 50%;
  background-color: #EE370F;
}
.level__number {
  position: absolute;
  left: -42px;
  top: -56px;
  font-weight: 800;
  font-size: 90px;
  line-height: 110px;
  color: #4B4B4B;
  letter-spacing: -0.01em;
  color: rgba(75, 75, 75, 0.2);
  text-transform: uppercase;
}
.level__name {
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #EE370F;
  margin-bottom: 16px;
}
@media (max-width: 1210px) {
  .level {
    width: 75%;
    margin-bottom: -48px;
  }
  .level__item {
    margin-bottom: 48px;
  }
}
@media (max-width: 960px) {
  .level {
    width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -69px;
    margin-top: 64px;
  }
  .level__item {
    width: 100%;
    padding: 0;
    margin-bottom: 69px;
  }
}
@media (max-width: 640px) {
  .level {
    margin-top: -15px;
    margin-bottom: -13px;
  }
  .level__name {
    margin-bottom: 28px;
  }
  .level__number {
    position: relative;
    left: initial;
    top: initial;
    text-align: center;
    margin-bottom: -54px;
  }
  .level__item {
    width: 358px;
    margin-bottom: 13px;
  }
}
@media (max-width: 480px) {
  .level__item {
    width: 100%;
  }
}
.advantages {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -45px;
}
.advantages__item {
  width: 25%;
  padding: 0 15px;
  margin-bottom: 45px;
}
.advantages__text {
  line-height: 1.6;
}
.advantages__icon {
  margin-bottom: 18px;
}
.advantages__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 136%;
  margin-bottom: 24px;
}
@media (max-width: 1210px) {
  .advantages {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .advantages__item {
    width: 50%;
    padding: 0 47px;
  }
}
@media (max-width: 960px) {
  .advantages {
    justify-content: center;
  }
  .advantages__icon {
    text-align: center;
  }
  .advantages__name {
    text-align: center;
  }
  .advantages__item {
    width: 490px;
    padding: 0;
  }
}
@media (max-width: 640px) {
  .advantages__item {
    width: 370px;
  }
}
@media (max-width: 480px) {
  .advantages__item {
    width: 100%;
  }
}
.btn-more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}
@media (max-width: 480px) {
  .btn-more {
    margin-top: 40px;
  }
}
.button {
  font-weight: 700 !important;
  font-size: 13px;
  line-height: 16px;
  min-height: inherit;
  text-transform: uppercase;
  padding: 9px 18px !important;
}
.button--green {
  background-color: #16821A !important;
  color: #FFFFFF !important;
}
.button--out-grey {
  color: rgba(0, 0, 0, 0.5) !important;
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  background-color: transparent !important;
}
.button--out-grey:hover {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.review {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -30px;
}
.review__top {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding-right: 55px;
  margin-bottom: 14px;
}
.review__name {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #4B4B4B;
}
.review__body p {
  font-size: 14px;
  line-height: 160%;
  margin: 0 0 10px 0;
}
.review__body p:last-child {
  margin-bottom: 0;
}
.review__avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 14px;
  background-position: center;
  background-size: cover;
}
.review__date {
  position: absolute;
  right: 0;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
}
.review__post {
  font-size: 12px;
  line-height: 15px;
  color: #4B4B4B;
  margin-top: 5px;
}
.review__item {
  width: calc(50% - 30px);
  padding: 20px 25px;
  margin: 0 15px 30px;
  background-color: #FFFFFF;
  border-radius: 1px;
}
@media (max-width: 960px) {
  .review {
    flex-direction: column;
    align-items: center;
    margin: 0 0 -30px;
  }
  .review__item {
    width: 488px;
    margin: 0 0 30px;
  }
}
@media (max-width: 640px) {
  .review__item {
    width: 100%;
  }
}
.form {
  color: #4B4B4B;
}
.form__title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 8px;
}
.form__title svg {
  margin-right: 12px;
}
.form__btn {
  margin-top: 11px;
}
.form__btn .button {
  width: 100%;
  font-weight: bold !important;
  min-height: 36px !important;
  padding: 9px 27px !important;
}
.form__field {
  height: 36px;
  margin-bottom: 12px;
}
.form__input {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: -0.01em;
  padding: 0 !important;
  text-align: center;
  height: 100%;
  width: 100%;
  color: #4B4B4B !important;
  background-color: #fff !important;
  min-height: initial !important;
  border-radius: 1px !important;
  box-shadow: none !important;
}
.form__text {
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 13px;
  color: #4B4B4B;
}
.form__text a {
  color: #4B4B4B;
  font-weight: bold;
  text-decoration: none;
}
@media (max-width: 1200px) {
  .form__body {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  .form__btn {
    order: 1;
    width: calc(33.3% - 20px);
    margin: 0 10px;
  }
  .form__field {
    order: 1;
    width: calc(33.3% - 20px);
    margin: 0 10px;
  }
  .form .agree {
    order: 2;
    margin: 10px 10px 0;
  }
}
@media (max-width: 800px) {
  .form__body {
    display: block;
  }
  .form__field {
    width: 100%;
    margin: 0 0 14px 0;
  }
  .form__btn {
    width: 100%;
    margin: 0 0 14px 0;
  }
  .form .agree {
    width: 100%;
    margin: 0 0 14px 0;
  }
}
.agree {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.5);
}
.agree a {
  color: rgba(0, 0, 0, 0.5);
}
.agree .check {
  margin-top: 2px;
}
.aside-form {
  width: 300px;
  padding: 22px 20px;
  background-color: #F1F1F1;
}
@media (max-width: 1200px) {
  .aside-form {
    width: 100%;
    padding: 22px 25px;
  }
}
.main-block {
  padding: 50px 0;
}
.check {
  position: relative;
  display: inline-flex;
  margin-right: 5px;
  flex: none;
}
.check input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.check__control {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  background-color: #FFFFFF;
  border-radius: 1px;
}
.main-slider {
  width: 100%;
  position: relative;
  outline: 0 !important;
  background-color: #F1F1F1;
}
.main-slider__img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 70px;
  top: 18px;
  width: 360px;
  height: 353px;
}
.main-slider__img img {
  max-width: 100%;
  max-height: 100%;
}
.main-slider .slick-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(29, 35, 41, 0.2) !important;
  border: 0 !important;
  width: 30px !important;
  height: 45px !important;
  min-width: inherit !important;
  min-height: inherit !important;
  padding: 0 !important;
  transition: background-color 0.3s ease;
}
.main-slider .slick-arrow:hover {
  background-color: rgba(29, 35, 41, 0.6) !important;
}
.main-slider .slick-arrow.slick-prev {
  left: 0;
  padding-left: 7px !important;
}
.main-slider .slick-arrow.slick-prev .svg-icon use {
  fill: #FFFFFF;
}
.main-slider .slick-arrow.slick-next {
  right: 0;
  padding-right: 7px !important;
}
.main-slider .slick-arrow.slick-next .svg-icon {
  transform: rotate(180deg);
}
.main-slider .slick-arrow.slick-next .svg-icon use {
  fill: #FFFFFF;
}
.main-slider .slick-dots {
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  list-style: none;
  position: absolute;
  bottom: 29px;
  margin: 0 auto;
  padding: 0;
}
.main-slider .slick-dots li {
  line-height: 1;
  display: inline-flex;
}
.main-slider .slick-dots li button {
  width: 5px;
  height: 5px;
  border-radius: 50% !important;
  margin: 0 4px !important;
  padding: 0  !important;
  min-width: inherit !important;
  min-height: inherit !important;
  font-size: 0 !important;
  border: 0 !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.main-slider .slick-dots li.slick-active button {
  background-color: #ffffff !important;
}
.main-slider::before {
  content: " ";
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 201px;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 53.94%);
}
.main-slider__title {
  font-family: Montserrat;
  font-weight: 800;
  font-size: 26px;
  line-height: 136%;
  text-transform: uppercase;
  color: #4B4B4B;
}
.main-slider__item {
  display: flex;
  align-items: center;
  position: relative;
  height: 330px;
  padding: 40px 380px 70px 50px;
}
.main-slider__container {
  width: calc(100% - 300px);
  padding-right: 40px;
}
@media (max-width: 1200px) {
  .main-slider__container {
    width: 100%;
    padding: 0;
    margin-bottom: 22px;
  }
  .main-slider__item {
    padding: 40px 460px 70px 50px;
  }
}
@media (max-width: 960px) {
  .main-slider::before {
    height: 143px;
  }
  .main-slider__title {
    font-size: 16px;
    line-height: 136%;
    width: 270px;
  }
  .main-slider__img {
    top: 12px;
    width: 256px;
    height: 251px;
  }
  .main-slider__item {
    height: 235px;
    padding: 44px 50px;
  }
}
@media (max-width: 640px) {
  .main-slider__item {
    height: 303px;
    padding: 40px;
    align-items: flex-end;
  }
  .main-slider__title {
    font-size: 14px;
    line-height: 136%;
    width: 100%;
    text-align: center;
  }
  .main-slider__img {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .main-slider .slick-dots {
    bottom: 15px;
  }
}
@media (max-width: 480px) {
  .main-slider__item {
    padding: 30px 30px;
  }
  .main-slider__img {
    width: 218px;
    height: 224px;
  }
  .main-slider__title {
    font-size: 12px;
    line-height: 136%;
  }
}
.offers {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -30px;
}
.offers__img {
  height: 200px;
  background-position: center;
  background-size: cover;
  position: relative;
  margin-bottom: 12px;
}
.offers__date {
  font-size: 13px;
  line-height: 128.91%;
  color: #fff;
  background: #16821a;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 4px 11px;
}
.offers__more .svg-icon {
  display: block;
}
.offers__more .svg-icon use {
  fill: rgba(0, 0, 0, 0.5);
  transition: fill 0.3s ease;
}
.offers__text {
  margin-bottom: 32px;
  color: #4B4B4B;
}
.offers__item {
  display: flex;
  flex-direction: column;
  width: calc(25% - 30px);
  margin: 0 15px 30px;
  font-size: 14px;
  line-height: 142.97%;
}
@media (max-width: 1199px) {
  .offers__item {
    width: calc(33.3% - 30px);
  }
  .offers__item:nth-child(4) {
    display: none;
  }
}
@media (max-width: 960px) {
  .offers__item {
    width: calc(50% - 30px);
  }
  .offers__item:nth-child(3) {
    display: none;
  }
}
@media (max-width: 480px) {
  .offers__item {
    width: calc(100% - 30px);
  }
  .offers__item:nth-child(2) {
    display: none;
  }
}

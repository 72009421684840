.btn-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}

@media(max-width: 480px) { 
    .btn-more {
        margin-top: 40px;
    }
}

.button {
    font-weight: 700 !important;
    font-size: 13px;
    line-height: 16px;
    min-height: inherit;
    text-transform: uppercase;
    padding: 9px 18px !important;

    &--green {
        background-color: #16821A !important;
        color: #FFFFFF !important;
    }

    &--out-grey {
        color: rgba(0, 0, 0, 0.5) !important;
        border: 1px solid rgba(0, 0, 0, 0.5) !important;
        background-color: transparent !important;

        &:hover {
            color: #fff !important;
            background-color: rgba(0, 0, 0, 0.5) !important;
        }
    }
}
.main-slider {
    width: 100%;
    position: relative;
    outline: 0 !important;
    background-color: #F1F1F1;

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 70px;
        top: 18px;
        width: 360px;
        height: 353px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    
    .slick-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute !important;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(29, 35, 41, 0.2) !important;
        border: 0 !important;
        width: 30px !important;
        height: 45px !important;
        min-width: inherit !important;
        min-height: inherit !important;
        padding: 0 !important;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: rgba(29, 35, 41, 0.6) !important;
        }

        &.slick-prev {
            left: 0;
            padding-left: 7px !important;

            .svg-icon {
                use {
                    fill: #FFFFFF;
                }
            }
        }

        &.slick-next {
            right: 0;
            padding-right: 7px !important;

            .svg-icon {
                transform: rotate(180deg);
                use {
                    fill: #FFFFFF;
                }
            }
        }
    }

    .slick-dots {
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
        list-style: none;
        position: absolute;
        bottom: 29px;
        margin: 0 auto;
        padding: 0;

        li {
            line-height: 1;
            display: inline-flex;
            
            button {
                width: 5px;
                height: 5px;
                border-radius: 50% !important;
                margin: 0 4px !important;
                padding: 0  !important;
                min-width: inherit !important;
                min-height: inherit !important;
                font-size: 0 !important;
                border: 0 !important;
                background-color: rgba(255, 255, 255, 0.3)!important;
            }

            &.slick-active {
                button {
                    background-color: rgba(255, 255, 255, 1) !important;
                }
            }
        }
    }

    &::before {
        content: " ";
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 201px;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 53.94%);
    }

    &__title {
        font-family: Montserrat;
        font-weight: 800;
        font-size: 26px;
        line-height: 136%;
        text-transform: uppercase;
        color: #4B4B4B;
    }

    &__item {   
        display: flex;
        align-items: center;
        position: relative;
        height: 330px;
        padding: 40px 380px 70px 50px;
    }

    &__container {
        width: calc(~"100% - 300px");
        padding-right: 40px;
    }
}

@media(max-width: 1200px) {
    .main-slider {
        &__container {
            width: 100%;
            padding: 0;
            margin-bottom: 22px;
        }

        &__item {
            padding: 40px 460px 70px 50px;
        }
    }
}

@media(max-width: 960px) {
    .main-slider {
        &::before {
            height: 143px;
        }

        &__title {
            font-size: 16px;
            line-height: 136%;
            width: 270px;
        }

        &__img {
            top: 12px;
            width: 256px;
            height: 251px;
        }

        &__item {
            height: 235px;
            padding: 44px 50px;
        }
    }
}

@media(max-width: 640px) {
    .main-slider {
        &__item {
            height: 303px;
            padding: 40px;
            align-items: flex-end;
        }

        &__title {
            font-size: 14px;
            line-height: 136%;
            width: 100%;
            text-align: center;
        }

        &__img {
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        .slick-dots {
            bottom: 15px;
        }
    }
}

@media(max-width: 480px) {
    .main-slider {
        &__item {
            padding: 30px 30px;
        }

        &__img {
            width: 218px;
            height: 224px;
        }

        &__title {
            font-size: 12px;
            line-height: 136%;
        }
    }
}